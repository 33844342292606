import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import algosdk from "algosdk";
import io from "socket.io-client";
import Application from "./Application";
import configureStore from "./redux/_store/configureStore";
import { PersistGate } from "redux-persist/lib/integration/react";
import LoaderComponent from "./components/common/LoaderCompoent/LoaderCompoent";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import { ALGO_SERVER, ALGO_INDEXER, SOCKET_URL } from "./constants/constants";
import { useEffect } from "react";

const { store, persistor } = configureStore();

if (window.Cypress) {
  window.store = store;
}

export const algodClient = new algosdk.Algodv2("", ALGO_SERVER, "");
export const algodClientIndexer = new algosdk.Algodv2("", ALGO_INDEXER, "");

export let socket;
if (window.io) {
  socket = window.io.socketClient;
} else {
  socket = io(SOCKET_URL, {
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionAttempts: 9999,
    transports: ["websocket"],
  });
}

const App = () => {
  useEffect(() => {
    socket.on("disconnect", () => {
      console.log("Socket disconnected App");
    });

    socket.on("connect_error", (err) => {
      console.log(`Socket connect_error due to ${err}`);
    });
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <LoaderComponent />
          <Application />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
