export const API_HOST = process.env.REACT_APP_API_URL;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
export const DATA_ENCRYPT_KEY = process.env.REACT_APP_DATA_ENCRYPT_KEY;
export const ALGO_SERVER = process.env.REACT_APP_ALGO_SERVER;
export const ALGO_INDEXER = process.env.REACT_APP_ALGO_INDEXER;
export const INDEXER_API = process.env.REACT_APP_INDEXER_API;
export const ALGOD_TOKEN = process.env.REACT_APP_ALGOD_TOKEN;
export const DISPENSER_LINK = process.env.REACT_APP_DISPENSER_LINK;
export const GLOWL_DECIMAL = 4;
export const ORDER_BOOK_SIZE = 100;
export const ORDER_BOOK_BUFFER = 50;
export const MARKET_TRADES_SIZE = 50;
export const WITHDRAW_FEE = 0.001 * 10 ** 6;
export const APP_CLOSEOUT_FEE = 0.002 * 10 ** 6;
export const EXCHANGE_NAME = "Ultradedev";
