import { actionTypes } from "../_actions/exchange.action";

const initialState = {
  // Tabs
  buySellTab: "B",
  limitMarketTab: "L",

  orderPrice: "",
  orderAmount: "",
  depositBalance: {},
  buyOrders: [],
  sellOrders: [],
  buyOrdersTolalCount: 0,
  sellOrdersTolalCount: 0,
  flashOrders: [],
  orderBook: {
    currentLtp: 0,
    lastLtp: 0,
  },
  pairStat: {
    high24: 0,
    low24: 0,
    volume24: 0,
    price24: 0,
  },
  marketTrades: [],
  userOpenOrder: [],
  userOrderHistory: [],
  userTradeHistory: [],
  listOfPairs: [],
  userWalletsBalances: [],
  minAlgoBalance: 0,
  priceDecimalLength: 0,
  baseDecimalLength: 0,
  stopWalletTimeInterval: false,
};

const exchange = (state = initialState, action) => {
  switch (action.type) {
    // ============== For User Data ==============
    case actionTypes.SAVE_USER_OPEN_ORDER:
      return {
        ...state,
        userOpenOrder: [...action.data],
      };
    case actionTypes.SAVE_USER_ORDER_HISTORY:
      return {
        ...state,
        userOrderHistory: [...action.data],
      };
    case actionTypes.SAVE_USER_TRADE_HISTORY:
      return {
        ...state,
        userTradeHistory: [...action.data],
      };
    // ============================================
    case actionTypes.SAVE_DEPOSIT_BALANCE:
      return {
        ...state,
        depositBalance: { ...action.data },
      };
    case actionTypes.SAVE_LTP:
      return {
        ...state,
        orderBook: action.data,
      };
    case actionTypes.BUY_ORDERS:
      return {
        ...state,
        buyOrders: [...action.data],
      };
    case actionTypes.SELL_ORDERS:
      return {
        ...state,
        sellOrders: [...action.data],
      };
    case actionTypes.BUY_ORDERS_TOTAL_COUNT:
      return {
        ...state,
        buyOrdersTolalCount: action.data,
      };
    case actionTypes.SELL_ORDERS_TOTAL_COUNT:
      return {
        ...state,
        sellOrdersTolalCount: action.data,
      };
    case actionTypes.SAVE_FLASH_ORDERS:
      return {
        ...state,
        flashOrders: [...action.data],
      };
    case actionTypes.SAVE_MARKET_TRADES:
      return {
        ...state,
        marketTrades: [...action.data],
      };
    case actionTypes.SAVE_PAIR_STAT:
      return {
        ...state,
        pairStat: action.data,
      };

    case actionTypes.LASTPRICE:
      return {
        ...state,
        lastPrice: action.data,
      };

    case actionTypes.STATE_DATA_UPDATE:
      return {
        ...state,
        [action.payload.prop]: action.payload.value,
      };

    case actionTypes.SAVE_ORDER_PRICE:
      return {
        ...state,
        orderPrice: action.data,
      };
    case actionTypes.SAVE_ORDER_AMOUNT:
      return {
        ...state,
        orderAmount: action.data,
      };
    case actionTypes.SAVE_BUY_SELL_TAB:
      return {
        ...state,
        buySellTab: action.data,
      };
    case actionTypes.SAVE_LIMIT_MARKET_TAB:
      return {
        ...state,
        limitMarketTab: action.data,
      };

    case actionTypes.SAVE_LIST_OF_PAIRS:
      return {
        ...state,
        listOfPairs: [...action.data],
      };
    case actionTypes.SAVE_USER_WALLETS_BALANCES:
      return {
        ...state,
        userWalletsBalances: [...action.data],
      };
    case actionTypes.SAVE_PAIR_DECIMALS:
      return {
        ...state,
        priceDecimalLength: action.data.priceDecimalLength,
        baseDecimalLength: action.data.baseDecimalLength,
      };
    case actionTypes.SAVE_STOP_WALLET_TIME_INTERVAL:
      return {
        ...state,
        stopWalletTimeInterval: action.data,
      };

    case actionTypes.SAVE_INITIAL_STATE:
      return {
        ...state,
        buySellTab: "B",
        limitMarketTab: "L",

        orderPrice: "",
        depositBalance: {},
        buyOrders: [],
        sellOrders: [],
        orderBook: {
          currentLtp: 0,
          lastLtp: 0,
        },
        pairStat: {
          high24: 0,
          low24: 0,
          volume24: 0,
          price24: 0,
        },
        marketTrades: [],
        userOpenOrder: 0,
        userOrderHistory: 0,
        userTradeHistory: 0,
      };

    case actionTypes.SAVE_INITIAL_WALLET_STATE:
      return {
        ...state,
        depositBalance: {},
        userOpenOrder: 0,
        userOrderHistory: 0,
        userTradeHistory: 0,
      };
    default:
      return state;
  }
};

export default exchange;
