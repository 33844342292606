export const SMALLESTUNIT = 100000000;
export const totallimit = 10000000000000000;

export const GRID_LAYOUT = {
  lg: [
    {
      i: "Market",
      x: 0,
      y: 0,
      h: 19,
      w: 7,
      minW: 4,
      minH: 5,
      resizeHandles: ["se"],
      isResizable: true,
    },
    {
      i: "TradeChart",
      x: 7,
      y: 0,
      h: 19,
      w: 11,
      minW: 6,
      minH: 6,
      resizeHandles: ["se"],
      isResizable: true,
    },
    {
      i: "OrderBook",
      x: 18,
      y: 0,
      h: 19,
      w: 6,
      minW: 3,
      minH: 2,
      resizeHandles: ["se"],
      isResizable: true,
    },
    {
      i: "MarketChanges",
      x: 37,
      y: 0,
      h: 5,
      w: 6,
      minW: 3,
      minH: 1,
      resizeHandles: ["se"],
      isResizable: false,
    },
    {
      i: "ConnectWallet",
      x: 37,
      y: 5,
      h: 13.6,
      w: 6,
      minW: 3,
      minH: 1,
      isResizable: false,
      resizeHandles: ["se"],
    },
    {
      i: "BuySellTab",
      x: 37,
      y: 18,
      h: 10.35,
      w: 6,
      minW: 3,
      minH: 1,
      isResizable: false,
      resizeHandles: ["se"],
    },
    {
      i: "OpenOrder",
      x: 0,
      y: 5,
      h: 10,
      w: 18,
      minW: 8,
      minH: 1,
      resizeHandles: ["se"],
      isResizable: true,
    },
    {
      i: "MarketTrades",
      x: 18,
      y: 2,
      h: 10,
      w: 6,
      minW: 3,
      minH: 1,
      resizeHandles: ["se"],
      isResizable: true,
    },
  ],

  md: [
    {
      i: "Market",
      x: 0,
      y: 0,
      h: 16,
      w: 8,
      minW: 4,
      minH: 4,
      resizeHandles: ["se"],
      isResizable: true,
    },
    {
      i: "TradeChart",
      x: 8,
      y: 0,
      h: 16,
      w: 8,
      minW: 6,
      minH: 6,
      resizeHandles: ["se"],
      isResizable: true,
    },
    {
      i: "OrderBook",
      x: 16,
      y: 0,
      h: 16,
      w: 7,
      minW: 3,
      minH: 2,
      resizeHandles: ["se"],
      isResizable: true,
    },
    {
      i: "MarketChanges",
      x: 25,
      y: 1,
      h: 5,
      w: 7,
      minW: 3,
      minH: 1,
      resizeHandles: ["se"],
      isResizable: false,
    },
    {
      i: "ConnectWallet",
      x: 24,
      y: 2,
      h: 11.2,
      w: 7,
      minW: 3,
      minH: 1,
      isResizable: false,
      resizeHandles: ["se"],
    },
    {
      i: "BuySellTab",
      x: 24,
      y: 2,
      h: 8.8,
      w: 7,
      minW: 3,
      minH: 1,
      isResizable: false,
      resizeHandles: ["se"],
    },
    {
      i: "OpenOrder",
      x: 0,
      y: 5,
      h: 9,
      w: 16,
      minW: 8,
      minH: 1,
      resizeHandles: ["se"],
      isResizable: true,
    },
    {
      i: "MarketTrades",
      x: 16,
      y: 2,
      h: 9,
      w: 7,
      minW: 3,
      minH: 1,
      resizeHandles: ["se"],
      isResizable: true,
    },
  ],

  sm: [
    {
      i: "Market",
      x: 0,
      y: 0,
      h: 15,
      w: 30,
      isResizable: false,
      isDraggable: false,
    },
    {
      i: "MarketTrades",
      x: 0,
      y: 15,
      h: 5,
      w: 30,
      isResizable: false,
      isDraggable: false,
    },
    // {
    //   i: "TradeChart",
    //   x: 0,
    //   y: 0,
    //   h: 15,
    //   w: 30,
    //   isResizable: false,
    //   isDraggable: false,
    // },
    // {
    //   i: "BuySellTab",
    //   x: 12,
    //   y: 0,
    //   h: 10,
    //   w: 30,
    //   isResizable: false,
    //   isDraggable: false,
    // },
    // {
    //   i: "OpenOrder",
    //   x: 0,
    //   y: 0,
    //   h: 20,
    //   w: 30,
    //   isResizable: false,
    //   isDraggable: false,
    // },
    // {
    //   i: "OrderBook",
    //   x: 0,
    //   y: 0,
    //   h: 20,
    //   w: 30,
    //   isResizable: false,
    //   isDraggable: false,
    // },
    // {
    //   i: "MarketChanges",
    //   x: 0,
    //   y: 0,
    //   h: 5,
    //   w: 30,
    //   isResizable: false,
    //   isDraggable: false,
    // },
    // {
    //   i: "ConnectWallet",
    //   x: 0,
    //   y: 5,
    //   h: 13,
    //   w: 30,
    //   isResizable: false,
    //   isDraggable: false,
    // },
  ],

  xs: [
    {
      i: "Market",
      x: 0,
      y: 0,
      h: 10,
      w: 30,
      isResizable: false,
      isDraggable: false,
    },
    {
      i: "MarketTrades",
      x: 0,
      y: 10,
      h: 9,
      w: 30,
      isResizable: false,
      isDraggable: false,
    },
    // {
    //   i: "TradeChart",
    //   x: 0,
    //   y: 0,
    //   h: 15,
    //   w: 30,
    //   isResizable: false,
    //   isDraggable: false,
    // },
    // {
    //   i: "BuySellTab",
    //   x: 12,
    //   y: 0,
    //   h: 10,
    //   w: 30,
    //   isResizable: false,
    //   isDraggable: false,
    // },
    // {
    //   i: "OpenOrder",
    //   x: 0,
    //   y: 0,
    //   h: 20,
    //   w: 30,
    //   isResizable: false,
    //   isDraggable: false,
    // },
    // {
    //   i: "OrderBook",
    //   x: 0,
    //   y: 0,
    //   h: 20,
    //   w: 30,
    //   isResizable: false,
    //   isDraggable: false,
    // },
    // {
    //   i: "MarketChanges",
    //   x: 0,
    //   y: 0,
    //   h: 5,
    //   w: 30,
    //   isResizable: false,
    //   isDraggable: false,
    // },
    // {
    //   i: "ConnectWallet",
    //   x: 0,
    //   y: 5,
    //   h: 13,
    //   w: 30,
    //   isResizable: false,
    //   isDraggable: false,
    // },
  ],
};

export const CUSTOMISE_VIEW_LIST = [
  {
    id: "Market",
    title: "filter_options.market_label",
  },
  {
    id: "TradeChart",
    title: "filter_options.tradechart_label",
  },
  {
    id: "OrderBook",
    title: "filter_options.orderbook_label",
  },
  {
    id: "OpenOrder",
    title: "filter_options.openorder_label",
  },
  {
    id: "MarketTrades",
    title: "filter_options.markettrade_label",
  },
];

export const FONT_LAYOUT = {
  openOrder: 11,
  tableHead: 11,
  tableBody: 11,
  tickerHead: 13,
  tickerArrow: 15,
  tickerPrice: 12,
  minFont: 9,
};
