import { algodClientIndexer } from "../App";
import { GLOWL_DECIMAL, ORDER_BOOK_SIZE } from "../constants/constants";
import { ApiServices } from "../services/api.services";

export const addressFormate = (data) => {
  return `${data?.substring(0, 4)}...${data?.substring(
    data.length - 4,
    data.length
  )}`;
};

export const getDecimalString = (amount, decimal) => {
  const value = amountValueFormate(amount, decimal);
  let a = String(value);
  let b = a.split(".").length == 2 ? a.split(".")[1].length : 0;
  return b;
};

export const amountValidationOnInput = (event, length) => {
  let t = event.currentTarget.value;

  event.currentTarget.value =
    t.indexOf(".") >= 0
      ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), length + 1)
      : t;
};

export const amountValidationOnKeyPress = (event, length) => {
  let newlength = 0;
  if (length === 0) {
    newlength = 0;
  } else {
    newlength = length - 1;
  }
  const valuePattern = new RegExp(`^(\\d{0,9}?.\\d{0,${newlength}})?$`);
  const { value } = event.currentTarget;
  if (value?.length > 0) {
    if (!valuePattern.test(value)) {
      event.preventDefault();
    }
  }

  const keyCodeArray = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
  if (length <= 0) {
  } else {
    keyCodeArray.push(46);
  }

  const keycheck = keyCodeArray.indexOf(event.which) > -1;
  if (!keycheck) {
    event.preventDefault();
  }
};

export const fetchWalletBalance = (assetId, decimal, data) => {
  if (assetId === 0) {
    if (data.balance > 0) {
      return Number((data.balance / 10 ** decimal).toFixed(decimal));
    }
    return 0;
  } else {
    if (data && data?.assets) {
      const asset = data?.assets.find((doc) => doc["asset-id"] === assetId);
      if (asset) {
        return Number((asset.amount / 10 ** decimal).toFixed(decimal));
      }
    }
    return 0;
  }
};

export const fetchAccountBalance = async (userAddress, assetId, decimal) => {
  try {
    const accountInfo = await algodClientIndexer
      .accountInformation(userAddress)
      .do();

    const data = accountInfo.account;

    if (assetId === 0) {
      if (data.amount > 0) {
        return Number((data.amount / 10 ** decimal).toFixed(decimal));
      }
      return 0;
    } else {
      if (data && data?.assets) {
        const asset = data?.assets.find((doc) => doc["asset-id"] === assetId);
        if (asset) {
          return Number((asset?.amount / 10 ** decimal).toFixed(decimal));
        }
      }
      return 0;
    }
  } catch (error) {
    // console.log("fetchAccountBalance Error : ", error);
    return 0;
  }
};

export const fetchAccountBalanceWithDecimal = async (userAddress, assetId) => {
  try {
    const accountInfo = await algodClientIndexer
      .accountInformation(userAddress)
      .do();

    const data = accountInfo.account;

    if (assetId === 0) {
      if (data.amount > 0) {
        return Number(data.amount);
      }
      return 0;
    } else {
      if (data && data?.assets) {
        const asset = data?.assets.find((doc) => doc["asset-id"] === assetId);
        if (asset) {
          return Number(asset?.amount);
        }
      }
      return 0;
    }
  } catch (error) {
    return 0;
  }
};

export const numberSystemFormate = (data) => {
  if (data) {
    return data.toLocaleString("en-US", {
      minimumFractionDigits: GLOWL_DECIMAL,
      maximumFractionDigits: GLOWL_DECIMAL,
    });
  } else {
    return (0).toLocaleString("en-US", {
      minimumFractionDigits: GLOWL_DECIMAL,
      maximumFractionDigits: GLOWL_DECIMAL,
    });
  }
};

export const listDateFormat = (date) => {
  let newDAte = new Date(date);
  let parsedDate = newDAte.toLocaleDateString("default", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
  let time = newDAte.toLocaleTimeString("en-US", {
    hour12: true,
    hour: "2-digit",
    minute: "2-digit",
  });

  return parsedDate + " " + time;
};

export const amountFormate = (amount, decimal, toFixDecimal) => {
  if (amount > 0) {
    return (amount / 10 ** decimal).toFixed(toFixDecimal);
  }
  return 0;
};

export const amountValueFormate = (amount, decimal) => {
  if (amount > 0) {
    return Number((amount / 10 ** decimal).toFixed(decimal));
  }
  return 0;
};

export const convertWithDecimals = (value, decimal) => {
  if (value > 0) {
    return Number((value * 10 ** decimal).toFixed(0));
  }
  return 0;
};
/**
 * Amount Volume Formation
 * 1K = 1,000
 * 1M = 1,000,000
 * 1B = 1,000,000,000
 * 1T = 1,000,000,000,000
 * @param {*} value
 * @param {*} decimal
 * @param {*} toFixDecimal
 * @returns
 */
export const amountVolumeFormation = (value, decimal, toFixDecimal) => {
  const bigToFix = 1;
  if (value <= 0 || value === undefined) {
    return 0;
  }
  const newValue = value / 10 ** decimal;
  if (newValue >= 10 ** 3 && newValue < 10 ** 6) {
    return `${(newValue / 10 ** 3).toFixed(bigToFix)}K`;
  } else if (newValue >= 10 ** 6 && newValue < 10 ** 9) {
    return `${(newValue / 10 ** 6).toFixed(bigToFix)}M`;
  } else if (newValue >= 10 ** 9 && newValue < 10 ** 12) {
    return `${(newValue / 10 ** 9).toFixed(bigToFix)}B`;
  } else if (newValue >= 10 ** 12) {
    return `${(newValue / 10 ** 12).toFixed(bigToFix)}T`;
  } else {
    return newValue.toFixed(toFixDecimal);
  }
};

export const walletBalanceFormate = (value) => {
  const bigToFix = 2;
  if (value <= 0 || value === undefined) {
    return 0;
  }

  const newValue = Number(value);
  if (newValue >= 10 ** 3 && newValue < 10 ** 6) {
    return `${(newValue / 10 ** 3).toFixed(bigToFix)}K`;
  } else if (newValue >= 10 ** 6 && newValue < 10 ** 9) {
    return `${(newValue / 10 ** 6).toFixed(bigToFix)}M`;
  } else if (newValue >= 10 ** 9 && newValue < 10 ** 12) {
    return `${(newValue / 10 ** 9).toFixed(bigToFix)}B`;
  } else if (newValue >= 10 ** 12) {
    return `${(newValue / 10 ** 12).toFixed(bigToFix)}T`;
  } else {
    return newValue;
  }
};

export const tradeValueFormate = (value, decimal) => {
  if (!value || value <= 0 || value === undefined) {
    return 0;
  } else {
    return value / 10 ** decimal;
  }
};

export const getAssetBalanceFromWalletData = (
  wallet_data,
  asset_id,
  asset_decimal
) => {
  if (asset_id === 0) {
    if (wallet_data.amount > 0) {
      return Number(
        (wallet_data.amount / 10 ** asset_decimal).toFixed(asset_decimal)
      );
    }
    return 0;
  } else {
    if (wallet_data && wallet_data?.assets) {
      const asset = wallet_data?.assets.find(
        (doc) => doc["asset-id"] === asset_id
      );
      if (asset) {
        return Number(
          (asset.amount / 10 ** asset_decimal).toFixed(asset_decimal)
        );
      }
    }
    return 0;
  }
};

export const getAccountBalance = async (
  address,
  base_id,
  base_decimal,
  price_id,
  price_decimal
) => {
  return new Promise((resolve, reject) => {
    ApiServices.getAccountAlgoMinBalance(address)
      .then((res) => {
        const baseBalance = getAssetBalanceFromWalletData(
          res.account,
          base_id,
          base_decimal
        );
        const priceBalance = getAssetBalanceFromWalletData(
          res.account,
          price_id,
          price_decimal
        );

        const minAlgoBalance = amountValueFormate(
          res.account["min-balance"],
          6
        );
        resolve({ address, baseBalance, priceBalance, minAlgoBalance });
      })
      .catch((error) => {
        reject({ address, baseBalance: 0, priceBalance: 0 });
      });
  });
};

export const orderAdd = (order, orderData, side) => {
  let newOrders = orderData;
  const indexfind = find_index(orderData, orderData.length, 0, order);

  if (indexfind >= orderData.length) {
    newOrders.push(order);
    if (newOrders.length > ORDER_BOOK_SIZE) {
      if (side === "B") {
        newOrders.pop();
      } else {
        newOrders.shift();
      }
    }
  } else {
    if (newOrders[indexfind][0] === order[0]) {
      newOrders[indexfind][1] = newOrders[indexfind][1] + order[1];
    } else {
      let a1 = newOrders.slice(0, indexfind);
      let a2 = newOrders.slice(indexfind, newOrders.length);
      a1.push(order);
      newOrders = a1.concat(a2);
      if (newOrders.length > ORDER_BOOK_SIZE) {
        if (side === "B") {
          newOrders.pop();
        } else {
          newOrders.shift();
        }
      }
    }
  }

  return newOrders;
};

export const orderSub = (order, orderData) => {
  let newOrders = orderData;
  const indexfind = newOrders.findIndex((doc) => doc[0] === order[0]);
  if (indexfind !== -1) {
    let newAmount = newOrders[indexfind][1] - order[1];
    if (newAmount <= 0) {
      newOrders.splice(indexfind, 1);
    } else {
      newOrders[indexfind][1] = orderData[indexfind][1] - order[1];
    }
  }
  return newOrders;
};

export const find_index = (arr, n, key, K) => {
  // Traverse the array
  for (let i = 0; i < n; i++) {
    if (arr[i][key] == K[key]) return i;
    else if (arr[i][key] > K[key]) continue;
    else if (arr[i][key] < K[key]) return i;
  }
  // If K is found
  // If current array element
  // exceeds K

  // If all elements are smaller
  // than K
  return n;
};
